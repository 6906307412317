import * as React from "react";
import { vernetzungsGruppeConfig } from "../../../config/VernetzungsgruppeConfig";
import { VernetzungsgruppeItem } from "./VernetzungsgruppeItem";

export function Vernetzungsgruppe() {
  return (
    <>
      <div className="pt-5 header">
        <h1>Vernetzungsgruppe</h1>
        <h2>Adressen von relevanten Organisationen</h2>
      </div>

      <p>
        Hier finden Sie relevante Adressen und Anschriften aller Mitglieder der
        Vernetzungsgruppe &laquo;sichtwechsel&raquo;.
      </p>
      <>
        {vernetzungsGruppeConfig.map((vernetzungsGruppe, i) => {
          return <VernetzungsgruppeItem vernetzungsGruppe={vernetzungsGruppe} index={i} key={i} />;
        })}
      </>
    </>
  );
}
