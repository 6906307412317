import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import { Vernetzungsgruppe } from "../components/pages/vernetzungsgruppe/Vernetzungsgruppe"

const VernetzungsgruppePage = () => (
  <Layout>
    <SEO title="Vernetzungsgruppe" />
    <Vernetzungsgruppe/>
  </Layout>
)

export default VernetzungsgruppePage
