import * as React from "react";

import ahv from "../images/vernetzungsgruppe/1.png";
import liechtensteinerBehindertenVerband from "../images/vernetzungsgruppe/2.png";
import amtFuerBerufsbildung from "../images/vernetzungsgruppe/3.png";
import specialOlympics from "../images/vernetzungsgruppe/6.png";
import theaterpaedagogischesZentrum from "../images/vernetzungsgruppe/8.png";
import krebshilfeLiechtenstein from "../images/vernetzungsgruppe/9.png";
import trialog from "../images/vernetzungsgruppe/10.png";
import unanders from "../images/vernetzungsgruppe/12.png";
import gehoerlosenKulturvereinLiechtenstein from "../images/vernetzungsgruppe/13.png";
import heilpaedagogischesZentrum from "../images/vernetzungsgruppe/16.png";
import vbw from "../images/vernetzungsgruppe/17.png";
import schulamt from "../images/vernetzungsgruppe/19.png";
import fragileOstschweiz from "../images/vernetzungsgruppe/20.png";
import auswaertige from "../images/vernetzungsgruppe/21.png";
import sozialDienste from "../images/vernetzungsgruppe/23.png";
import familienhilfe from "../images/vernetzungsgruppe/25.png";
import liechtensteinInstitut from "../images/vernetzungsgruppe/26.png";
import ombudsstelle from "../images/vernetzungsgruppe/27.png";
import sachwalterVerein from "../images/vernetzungsgruppe/28.png";
import aha from "../images/vernetzungsgruppe/Logo_aha.png";
import demenzLiechtenstein from "../images/vernetzungsgruppe/Logo_Demenz_Liechtenstein.png";
import vereinFuerMenschenrechte from "../images/vernetzungsgruppe/Logo_Verein_fuer_Menschenrechte.png";
import IVernetzungsGruppe from "../components/pages/vernetzungsgruppe/IVernetzungsgruppe";

export const vernetzungsGruppeConfig: Array<IVernetzungsGruppe> = [
  {
    image: ahv,
    name: "Liechtensteinische AHV-IV-FAK",
    address: "Gerberweg 2, 9490 Vaduz",
    telephone: "+423 238 16 16",
    links: ["ahv@ahv.li", "https://www.ahv.li"],
  },
  {
    image: sachwalterVerein,
    name: "Sachwalterverein",
    address: "An der Halde 3, 9495 Triesen",
    telephone: "399 30 90",
    links: ["info@sachwalterverein.li", "http://www.sachwalterverein.li"],
    additionalContent: [
      "Der Sachwalterverein ist ein privatrechtlicher Verein, der die gesetzliche Vertretung und Förderung der Interessen von volljährigen Personen mit psychischer Erkrankung oder geistiger Behinderung bezweckt. Der Verein ist von der Regierung des Fürstentums Liechtenstein beauftragt, Aufgaben im Bereich der Sachwalterschaft (Vormundschaft) zu übernehmen.",
    ],
  },
  {
    image: krebshilfeLiechtenstein,
    name: "Krebshilfe Liechtenstein",
    address: "Im Malarsch 4, 9494 Schaan",
    telephone: "+423 233 18 45",
    contactPeople: ["Marion Leal"],
    links: ["marion.leal@krebshilfe.li", "https://www.krebshilfe.li"],
    additionalContent: [
      "Die Krebshilfe Liechtenstein wurde 1988 gegründet. Sie ist ein gemeinnütziger Verein und Mitglied der Krebsliga Schweiz. Die Beratungs- und Geschäftsstelle der Krebshilfe steht allen Menschen offen, die von Krebs betroffen sind oder mehr über die Krankheit und die Vorsorgemöglichkeiten wissen möchten. Ob telefonisch, persönlich oder per Internet – unser Beratungsangebot und unsere Dienstleistungen sind kostenlos. Damit wir für eine persönliche Beratung genügend Zeit einplanen können, ist eine Voranmeldung wünschenswert.",
    ],
  },
  {
    image: trialog,
    name: "Trialog-Gruppe Schaan",
    address: "Hofistrasse 22, 9497 Triesenberg",
    telephone: "+423 268 39 48",
    contactPeople: ["Barbara Bargetze"],
    links: ["info@trialog-liechtenstein.li", "https://www.trialog-liechtenstein.li"],
    additionalContent: [
      "Für den Abbau von Vorurteilen gegenüber psychisch erkrankten Menschen.",
    ],
  },
  {
    image: gehoerlosenKulturvereinLiechtenstein,
    name: "Gehörlosen Kulturverein Liechtenstein",
    address: "Postfach 348, 9490 Vaduz",
    links: ["info@deaf.li", "https://www.deaf.li"],
    additionalContent: [
      "Der Verein wurde 1993 gegründet. Alle Mitglieder im Vorstand sind gehörlos und kommunizieren in ihrer Muttersprache, der Gebärdensprache.",
      "Die Gehörlosen möchten mehr Wissen über die Gebärdensprachgemeinschaft bekannt machen. Die Kommunikation funktioniert -  man muss nur ein paar Grundregeln kennen oder eine/n Gebärdensprachdolmetscher/in hinzuziehen.",
      "Mit dem Weiterbildungsprogramm soll die Gebärdensprachgemeinschaft in ihrer Identität gestärkt und Wissenslücken gefüllt werden.",
      "Dem Verein ist es wichtig, ein positives Bild zum Thema Gehörlosigkeit und Gebärdensprache zu vermitteln. Wie geht das, nicht hören können, aber trotzdem den Alltag zu meistern?",
    ],
  },
  {
    image: heilpaedagogischesZentrum,
    name: "Heilpädagogisches Zentrum",
    address: "Bildgass 1, 9494 Schaan",
    telephone: "+423 237 61 61",
    links: ["admin@hpz.li", "https://www.hpz.li"],
    additionalContent: [
      "Das hpz erfüllt Aufgaben in den unterschiedlichen Themen der liechtensteinischen Gesellschaft. Die Sonderpädagogische Tagesschule, unterteilt in Sonderpädagogische Schule und Sprachheilschule, und der umfangreiche Therapiebereich für Kinder und Jugendliche ist Teil der liechtensteinischen Bildungslandschaft. Die Werkstätten mit ihren vielfältigen Dienstleistungen und ihren Produkten sind Bestandteil der Wirtschaftskraft Liechtensteins. Zusammen mit dem Bereich Wohnen und seinem Wohnangebot für Menschen mit besonderen Bedürfnissen erfüllen sie Aufgaben des liechtensteinischen Sozialwesens.",
    ],
  },
  {
    image: liechtensteinerBehindertenVerband,
    name: "Liechtensteiner Behinderten-Verband",
    address: "Wiesengass 17, 9494 Schaan",
    telephone: "+423 390 05 15",
    links: ["lbv@lbv.li", "https://www.lbv.li"],
    additionalContent: [
      "Der Liechtensteiner Behinderten-Verband ist eine private Selbsthilfeorganisation für Menschen mit Behinderung in Liechtenstein. Der Verband wurde 1953 gegründet und zählt heute rund 500 Mitglieder. Der Zweck des Vereins besteht in der Wahrung und Förderung aller Interessen von Menschen mit speziellen Bedürfnissen.",
    ],
  },
  {
    image: aha,
    name: "aha - Tipps und Infos für junge Leute",
    address: "Kirchstrasse 10, 9490 Vaduz",
    telephone: "+423 239 91 10",
    links: ["aha@aha.li", "https://www.aha.li"],
    additionalContent: <i>
      <p>Das aha – Tipps &amp; Infos für junge Leute ist ein Jugendinformationszentrum und nationaler Akteur der landesweiten Jugendarbeit Liechtensteins und darüber hinaus der internationalen Begegnungen für junge Menschen von 13 bis 30 Jahren.</p>
      <p>Gemeinsam mit zahlreichen ehrenamtlichen Helfer/innen und Partnerorganisationen aus Deutschland, Südtirol und der Schweiz bietet das aha über Pfingsten jeweils das Eurolager für junge Menschen mit und ohne Behinderungen durch. Ein erlebnisreiches Lager um Brücken aufzubauen. Weitere Infos: <a href="http://www.eurolager.com" target="_blank" rel="noreferrer">www.eurolager.org</a></p>
    </i>,
  },
  {
    image: theaterpaedagogischesZentrum,
    name: "Theaterpädagogisches Zentrum",
    address: "Zollstrasse 52, 9494 Schaan",
    telephone: "+423 232 14 44",
    contactPeople: ["Beatrice Brunhart-Risch, Eveline Kaufmann-Hasler"],
    links: ["info@jungestheater.li", "https://www.jungestheater.li"],
    additionalContent: [
      "Das junge THEATER liechtenstein ist ein selbständiger Verein und realisiert seit 2001 jährlich professionell geleitete Theaterproduktionen mit Kindern, Jugendlichen und Erwachsenen mit und ohne geistiger Behinderung. Zudem ist es das theaterpädagogische Zentrum und koordiniert die theaterpädagogischen Angebote in den Schulen Liechtensteins.",
    ],
  },
  {
    image: amtFuerBerufsbildung,
    name: "Amt für Berufsbildung und Berufsberatung",
    address: "Postplatz 2, 9494 Schaan",
    telephone: "+423 236 72 00 (Nach telefonischer Voranmeldung)",
    links: ["info.abb@llv.li", "https://abb.llv.li"],
    additionalContent: [
      "Das Amt für Berufsbildung und Berufsberatung (ABB) ist verantwortlich für die Information und Beratung von Jugendlichen und Erwachsenen in Berufs-, Studien- und Laufbahnfragen. Die Abteilung Berufsberatung begleitet dabei auch Personen, die sich wegen Erkrankung und Verunfallung (Invalidität) beruflich verändern müssen.",
    ],
  },
  {
    image: vbw,
    name: "Verein für Betreutes Wohnen (VBW)",
    address: "Reberastrasse 4, 9494 Schaan",
    telephone: "+423 235 00 20",
    contactPeople: ["Heidi Derungs Hasler, Geschäftsführerin"],
    links: ["geschäftsführung@vbw.li", "https://www.vbw.li"],
  },
  {
    image: sozialDienste,
    name: "Amt für Soziale Dienste",
    address: "Postplatz 4, 9494 Schaan",
    telephone: "+423 236 72 72",
    links: ["info@asd.llv.li", "https://asd.llv.li"],
    additionalContent: [
      "Das Amt für Soziale Dienste unterstützt familiäre Systeme, wo dies zum Schutz und Wohl der Kinder und Jugendlichen notwendig ist. Es fördert die ausserschulische Kinder- und Jugend-arbeit und ist zuständig für den Jugendschutz. Bei hilfsbedürftigen erwachsenen Personen leistet das Amt persönliche und wirtschaftliche Hilfe. Das Amt ist im Weiteren zuständig für die Gewährleistung und Entwicklung der psychosozialen Versorgung im Land.",
    ],
  },
  {
    image: auswaertige,
    name: "Amt für Auswärtige Angelegenheiten",
    address: "Heiligkreuz 14, Postfach 684, 9490 Vaduz",
    telephone: "+423 236  60 58 (Bitte um telefonische Voranmeldung)",
    links: ["info.aaa@llv.li", "https://aaa.llv.li"],
    additionalContent: [
      "Das Amt für Auswärtige Angelegenheiten wahrt in Zusammenarbeit mit den diplomatischen Vertretungen die Interessen Liechtensteins im Ausland. Es pflegt die Beziehungen zu anderen Staaten, wirkt bei der Aushandlung von Abkommen mit und vertritt die Anliegen Liechtensteins in internationalen Organisationen.",
    ],
  },
  {
    image: schulamt,
    name: "Schulamt",
    address: "Austrasse 79, Postfach 684, 9490 Vaduz",
    telephone: "+423 236 67 70",
    links: ["info.sa@llv.li", "https://sa.llv.li"],
    additionalContent: [
      "Das Schulamt ist für die Planung, Aufrechterhaltung und Weiterentwicklung des Schulbetriebs aller öffentlichen Schulen in Liechtenstein verantwortlich. Das sind ca. 270 Klassen mit 4‘500 Schülerinnen und Schülern. Es trägt die Personalverantwortung für rund 630 Lehrpersonen sowie alle Schulleitungen.",
      "Zum Schulamt gehören weitere Dienstleistungen wie z. B. der Schulpsychologische Dienst und die Schulsozialarbeit.",
    ],
  },
  {
    image: specialOlympics,
    name: "Special Olympics Liechtenstein",
    address: "Wiesengass 17, 9494 Schaan",
    contactPeople: ["Brigitte Marxer"],
    telephone: "+423 793 99 75",
    links: ["info@specialolympics.li", "http://www.specialolympics.li"],
    additionalContent: [
      "Special Olympics ist ein internationales Sportprogramm für Menschen mit einer geistigen oder mehrfachen Behinderung, das weltweit Training und Anlässe ermöglicht. 1968 in den USA gegründet, ist es seit 1992 vom Internationalen Olympischen Komitee offiziell anerkannt. Special Olympics sind Breitensport-Programme, die eine allgemeine körperliche Fitness und Beweglichkeit fördern; sie tragen dazu bei, das Selbstbewusstsein, die Freude an der Bewegung und die sportliche Leistungsfähigkeit zu stärken.",
    ],
  },
  {
    image: unanders,
    name:
      "Selbsthilfegruppe ‚unanders‘ für Familien von Kindern mit Behinderung",
    address: "Landstrasse 67, 9490 Vaduz",
    contactPeople: ["Eva Wohlwend"],
    telephone: "+41 79 695 17 66",
    links: ["info@unanders.li", "https://www.unanders.li"],
    additionalContent: [
      "Rund 15 Familien mit Kindern mit Behinderung aus Liechtenstein und der angrenzenden Region sind Mitglieder von ‚unanders‘. In regelmässigen Treffen pflegen wir den Gedanken- und Erfahrungsaustausch und geben uns gegenseitig Hilfestellung. Suchen Sie einen Ansprechpartner? Nehmen Sie mit uns Kontakt auf, wir freuen uns und helfen gerne weiter!",
    ],
  },
  {
    image: fragileOstschweiz,
    name:
      "Fragile Ostschweiz – Vereinigung für hirnverletzte Menschen und Angehörige",
    address: "Kirchstrasse 34, Postfach 233, 9430 St. Margrethen",
    contactPeople: ["Pius Böni"],
    telephone: "+41 71 740 13 00",
    links: ["ostschweiz@fragile.ch", "https://www.fragile-ostschweiz.ch"],
    additionalContent: [
      "FRAGILE Ostschweiz besteht seit 1999 und bietet Menschen mit einer Hirnverletzung und ihren Angehörigen sowie Fachpersonen Unterstützung durch Beratungen, Selbsthilfegruppen (z. B. in Buchs/Räfis), Vorträge, Informationen und Veranstaltungen. Mit Fachbroschüren orientiert die Vereinigung kompetent über das Thema Hirnverletzung. Alle Angebote stehen selbstverständlich auch den Bewohnerinnen und Bewohnern des Fürstentum Liechtensteins offen. Haben Sie Fragen, kontaktieren Sie uns einfach!",
    ],
  },
  {
    image: familienhilfe,
    name: "Familienhilfe Liechtenstein e.V.",
    address: "Schwefelstrasse 14, 9490 Vaduz",
    contactPeople: ["Barbara Frommelt"],
    telephone: "+423 236 00 66",
    links: ["barbara.frommelt@familienhilfe.li", "https://www.familienhilfe.li"],
    additionalContent: (
      <i>
        Die Familienhilfe bietet eine umfassende, auf die Situation und den
        Bedarf abgestimmte Pflege und Betreuung zu Hause an
        <ul>
          <li>
            Unterstützung bei der Aufrechterhaltung des familiären Tagesablaufs
            bei Krankheit, Unfall, Wochenbett, Spital- und Kuraufenthalten der
            haushaltführenden Person
          </li>
          <li>Spitex</li>
          <li>
            Betreuung und Unterstützung für betagte und chronisch kranke
            Menschen
          </li>
          <li>Entlastung von pflegenden Angehörigen</li>
          <li>Mahlzeitendienst </li>
        </ul>
      </i>
    ),
  },
  {
    image: liechtensteinInstitut,
    name:
      "Liechtenstein-Institut",
    address: "St. Luziweg 2, 9487 Bendern",
    contactPeople: ["Patricia Hornich"],
    telephone: "+423 375 88 50",
    links: ["patricia.hornich@liechtenstein-institut.li", "https://www.liechtenstein-institut.li"],
    additionalContent: [
      "Das Liechtenstein-Institut wurde 1986 als privater, gemeinnütziger Verein gegründet. Das Institut ist als Hochschulforschungsinstitut und Lehrstätte in den Fachgebieten Recht, Politik, Wirtschaft, Geschichte tätig. Das Ziel besteht darin, einen Beitrag zum liechtensteinischen Selbstverständnis zu schaffen. Dies erfolgt durch Forschung, Lehre und Publikationen zu Liechtenstein und Liechtenstein-relevanten Themen."
    ],
  },
  {
    image: vereinFuerMenschenrechte,
    name:
      "Verein für Menschenrechte",
    address: "Werdenbergerweg 20, 9490 Vaduz",
    contactPeople: ["Christian Blank"],
    telephone: "+423 230 22 36",
    links: ["christian.blank@vmr.li", "https://www.menschenrechte.li"],
    additionalContent: [
      "Der Verein für Menschenrechte in Liechtenstein (VMR) wurde am 10. Dezember 2016, am Internationalen Tag der Menschenrechte, gegründet und ist seit dem 1. Januar 2017 tätig. Der VMR ist die nationale Menschenrechtsinstitution gemäss den Pariser Prinzipien der UNO. Zu seinen Aufgaben gehören unter anderem die Beratung von Privaten und Behörden, die Information und Sensibilisierung der Öffentlichkeit sowie das Monitoring der Menschenrechtssituation in Liechtenstein. Gleichzeitig ist der VMR Anlaufstelle für Menschen, die sich in ihren Rechten verletzt fühlen und er unterstützt Opfer von Menschenrechtsverletzungen."
    ],
  },
  {
    image: ombudsstelle,
    name:
      "Ombudsstelle für Kinder und Jugendliche",
    address: "Werdenbergerweg 20, 9490 Vaduz",
    contactPeople: ["Margot Sele"],
    telephone: "+423 230 22 33",
    links: ["margot.sele@oskj.li", "https://www.oskj.li"],
    additionalContent: [
      "Die OSKJ-Ombudsstelle für Kinder und Jugendliche ist eine allgemein zugängliche Anlauf- und Beschwerdestelle in Kinder- und Jugendfragen. Sie setzt sich für die Interessen von Kindern und Jugendlichen in Liechtenstein ein."
    ],
  },
  {
    image: demenzLiechtenstein,
    name:
      "Demenz Liechtenstein",
    address: "Im Malarsch 4, 9494 Schaan",
    contactPeople: ["Matthias Brüstle"],
    telephone: "+423 230 34 45",
    links: ["kontakt@demenz.li", "https://www.demenz.li"],
    additionalContent: [
      "DEMENZ LIECHTENSTEIN macht sich für die Thematik stark. Wir bieten z.B. Beratung und Unterstützung von Menschen, die von einer Demenzerkrankung direkt oder indirekt betroffen sind. Und vieles mehr. Kommen Sie mit allen Fragen zu Demenz gerne auf uns zu!"
    ],
  },
];
