import * as React from "react";
import IVernetzungsGruppe from "./IVernetzungsgruppe";

export interface IVernetzungsGruppeItemProps {
  vernetzungsGruppe: IVernetzungsGruppe;
  index: number;
}

export function VernetzungsgruppeItem(props: IVernetzungsGruppeItemProps) {
  const { vernetzungsGruppe, index } = props;
  const {
    image,
    name,
    address,
    telephone,
    contactPeople,
    links,
    additionalContent,
  } = vernetzungsGruppe;

  const getContactPeople = () => {
    if (contactPeople.length > 1) {
      return (
        <p className="p-0 m-0">Kontaktpersonen: {contactPeople.join(", ")}</p>
      );
    }
    return <p className="p-0 m-0">Kontaktperson: {contactPeople[0]}</p>;
  };

  const getAdditionalContact = () => {
    if (React.isValidElement(additionalContent)) {
      return additionalContent;
    }
    return (
      <i>
        {additionalContent.map((content) => {
          return <p>{content}</p>;
        })}
      </i>
    );
  };

  return (
    <div className="d-flex flex-xl-row flex-column align-items-center p-3 vernetzungsgruppe">
      <div className="text-center first-column">
        <a href={links[1]} target="_blank">
          <img src={image} title={name} alt={`Logo von ${name}`} />
        </a>
      </div>

      <div className="p-3 second-column align-self-start">
        <p className="p-0 m-0 font-weight-bold">{name}</p>
        <p className="p-0 m-0">{address}</p>
        {telephone && <p className="p-0 m-0">Telefon: {telephone}</p>}
        {contactPeople && getContactPeople()}
        <p className="d-flex flex-xl-row flex-column pb-3 m-0">
          {links
            .map((link) => {
              return <a className="link" href={link.includes("@") ? `mailto:${link}` : link} target="_blank">{link.replace('http://', '').replace('https://', '')}</a>;
            })}
        </p>
        {additionalContent && getAdditionalContact()}
      </div>
    </div>
  );
}
